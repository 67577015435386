"use strict";

angular.module("informaApp")
    .service("TooltipsService", ["BaseApiService", "ConstantsSvc", function (BaseApiService, ConstantsSvc) {
        function arrayToObject(array) {
            if (!array){
                return {};
            }

            var obj = {};

            for (var i = 0; i < array.length; i++){
                obj[array[i].key] = array[i].text;
            }

            return obj;
        }
        return {
            getAllTooltipsAsObject: function () {
                return this.getAllTooltips()
                    .then(function (response) {
                        return arrayToObject(response);
                    });
            },
            getAllTooltips: function () {
                return BaseApiService.get('tooltips')
                .then(function (response) {
                    return response.data.data;
                });
                /*
                return $http.get(ConstantsSvc.API.URL + "tooltips")
                    .then(function (response) {
                        return response.data.data;
                    });
                */
            },
            createOrUpdate: function (key, name, text) {
                return BaseApiService.put('tooltips', {
                    name: name,
                    text: text,
                    key: key
                });
                // return $http.put(ConstantsSvc.API.URL + "tooltips",  );
            }
        };
    }]);